import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import GavelIcon from "@mui/icons-material/Gavel";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  SpeedDial,
  SpeedDialAction,
  Stack,
  TextField,
} from "@mui/material";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useRoute } from "wouter";
import AppBar from "../components/AppBar";
import SmallIconButton from "../components/SmallIconButton";
import { translateTrait } from "../components/TranslatedTrait";
import FirebaseNotificationsCard from "../components/plugin/firebase_messaging/FirebaseNotificationsCard";
import IBuilding, { saveBuilding } from "../services/Building";
import IRule, { saveRule } from "../services/Rule";
import { IElectricityContract } from "../services/plugin/Nordpool";
import buildingStore, { refreshBuilding } from "../stores/BuildingStore";
import deviceStore, { getDeviceText } from "../stores/DeviceStore";
import gatewayStore from "../stores/GatewayStore";
import groupStore from "../stores/GroupStore";
import roomStore from "../stores/RoomStore";
import ruleStore from "../stores/RuleStore";
import stateStore from "../stores/StateStore";
import chartUIStore from "../stores/UI/ChartUIStore";
import deviceUIStore from "../stores/UI/DeviceUIStore";
import groupUIStore from "../stores/UI/GroupUIStore";
import roomUIStore from "../stores/UI/RoomUIStore";
import stateUIStore from "../stores/UI/StateUIStore";
import firebaseMessagingStore from "../stores/plugin/FirebaseMessaging";
import electricityContractStore from "../stores/plugin/Nordpool";
import ErrorScreen from "./ErrorScreen";

const useStyles = makeStyles({
  cardContent: {
    paddingTop: "0 !important",
  },
  chip: {
    width: "100%",
    "& .MuiSvgIcon-root": {
      marginLeft: 12,
    },
  },
});

const BuildingSettingsScreen = observer(() => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [, params] = useRoute("/building/:uuid/settings/");
  const [, setLocation] = useLocation();
  const uuid = params && params.uuid ? params.uuid : "";
  const [building, setBuilding] = useState<IBuilding>(
    buildingStore.find(uuid) || {
      uuid: "",
      name: "",
    },
  );
  const [isValid, setIsValid] = useState(false);
  const [refreshInProgress, setRefreshInProgress] = useState(false);
  const [search, setSearch] = useState<string>("");
  const states = stateStore
    .filter({ building_id: building.uuid, sorted: true })
    .filter((state) => state.name.toLowerCase().includes(search.toLowerCase()));
  const groups = groupStore
    .filter({ building_id: building.uuid, sorted: true })
    .filter((group) => group.name.toLowerCase().includes(search.toLowerCase()));
  const rooms = roomStore
    .filter({ building_id: building.uuid, sorted: true })
    .filter((room) => room.name.toLowerCase().includes(search.toLowerCase()));
  const devices = deviceStore
    .filter({ building_id: building.uuid, sorted: true })
    .filter((device) =>
      device.name.toLowerCase().includes(search.toLowerCase()),
    );
  const gateways = gatewayStore
    .filter({ building_id: building.uuid })
    .filter((gateway) =>
      gateway.name.toLowerCase().includes(search.toLowerCase()),
    );
  const rules = ruleStore
    .filter({ building_id: building.uuid, sorted: true })
    .filter((rule) => rule.name.toLowerCase().includes(search.toLowerCase()));
  const charts = chartUIStore.filter({
    building_id: building.uuid,
    sorted: true,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const electricityContracts: IElectricityContract[] =
    electricityContractStore.filter({
      building_id: building.uuid,
      sorted: true,
    });

  useEffect(() => {
    if (building.name.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [building, building.name]);

  const onSave = () => {
    saveBuilding(building).then(() => window.history.back());
  };

  const onRefresh = () => {
    setRefreshInProgress(true);
    refreshBuilding(building.uuid).then(() => setRefreshInProgress(false));
  };

  const onStateDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newOrder = [...states.map((state) => state.uuid)];
    const [moved] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, moved);
    stateUIStore.setOrder(newOrder);
  };

  const onGroupDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newOrder = [...groups.map((group) => group.uuid)];
    const [moved] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, moved);
    groupUIStore.setOrder(newOrder);
  };

  const onRoomDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newOrder = [...rooms.map((room) => room.uuid)];
    const [moved] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, moved);
    roomUIStore.setOrder(newOrder);
  };

  const onChartDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newOrder = [...charts.map((chart) => chart.id)];
    const [moved] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, moved);
    chartUIStore.setOrder(newOrder);
  };

  const onRuleToggle = (rule: IRule) => {
    // eslint-disable-next-line no-alert
    if (
      window.confirm(
        rule.disabled
          ? t('Do you really want to enable rule "{{name}}"?', {
              name: rule.name,
            })
          : t('Do you really want to disable rule "{{name}}"?', {
              name: rule.name,
            }),
      )
    ) {
      saveRule({
        ...rule,
        disabled: !rule.disabled,
      });
    }
  };

  const onDeviceDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newOrder = [...devices.map((device) => device.uuid)];
    const [moved] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, moved);
    deviceUIStore.setOrder(newOrder);
  };

  return (
    <Box>
      <AppBar
        title={building?.name}
        withBack
        setSearch={setSearch}
        right={
          isValid && (
            <SmallIconButton
              variant="contained"
              color="secondary"
              text={t("Save")}
              startIcon={<SaveIcon />}
              onClick={onSave}
            />
          )
        }
      />
      <ErrorScreen>
        <Container maxWidth="xl" sx={{ marginTop: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Card>
                <CardHeader title={t("Building settings")} />
                <CardContent className={styles.cardContent}>
                  <Stack spacing={2} marginBottom={-1}>
                    <TextField
                      variant="outlined"
                      type="text"
                      label="Name"
                      defaultValue={building.name}
                      onChange={(event) =>
                        setBuilding({ ...building, name: event.target.value })
                      }
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={refreshInProgress}
                      startIcon={<RefreshIcon />}
                      onClick={onRefresh}
                    >
                      {refreshInProgress
                        ? t("Refreshing")
                        : t("Refresh everything")}
                    </Button>
                    {gateways.some((gateway) => gateway.discovery) ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<ClearIcon />}
                        onClick={() =>
                          gateways.forEach((gateway) =>
                            gatewayStore.turnDiscoveryOff(gateway),
                          )
                        }
                      >
                        {t("Stop discovery")}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={() =>
                          gateways.forEach((gateway) =>
                            gatewayStore.turnDiscoveryOn(gateway),
                          )
                        }
                      >
                        {t("Discover new devices")}
                      </Button>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            {firebaseMessagingStore.plugin_enabled && (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <FirebaseNotificationsCard buildings={[building.uuid]} />
              </Grid>
            )}
            {charts.length > 0 && (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Card>
                  <CardHeader title={t("Charts")} />
                  <CardContent className={styles.cardContent}>
                    <DragDropContext onDragEnd={onChartDragEnd}>
                      <Droppable droppableId={`charts-${building.uuid}`}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.droppableProps}
                          >
                            {charts.map((chart, index) => (
                              <Draggable
                                key={chart.id}
                                draggableId={chart.id}
                                index={index}
                              >
                                {(providedDraggable) => (
                                  <div
                                    ref={providedDraggable.innerRef}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...providedDraggable.draggableProps}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...providedDraggable.dragHandleProps}
                                    style={{
                                      ...providedDraggable.draggableProps.style,
                                    }}
                                  >
                                    <Chip
                                      label={
                                        chart.name ||
                                        translateTrait(chart.traitClass)
                                      }
                                      className={styles.chip}
                                      deleteIcon={<EditIcon />}
                                      onDelete={() =>
                                        setLocation(
                                          `/building/${building.uuid}/chart/${chart.id}/`,
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {states.length > 0 && (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Card>
                  <CardHeader title={t("States")} />
                  <CardContent className={styles.cardContent}>
                    <DragDropContext onDragEnd={onStateDragEnd}>
                      <Droppable droppableId={`states-${building.uuid}`}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.droppableProps}
                          >
                            {states.map((state, index) => (
                              <Draggable
                                key={state.uuid}
                                draggableId={state.uuid}
                                index={index}
                              >
                                {(providedDraggable) => (
                                  <div
                                    ref={providedDraggable.innerRef}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...providedDraggable.draggableProps}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...providedDraggable.dragHandleProps}
                                    style={{
                                      ...providedDraggable.draggableProps.style,
                                    }}
                                  >
                                    <Chip
                                      label={state.name}
                                      className={styles.chip}
                                      deleteIcon={<EditIcon />}
                                      onDelete={() =>
                                        setLocation(
                                          `/building/${building.uuid}/state/${state.uuid}/`,
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {rules.length > 0 && (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Card>
                  <CardHeader title={t("Rules")} />
                  <CardContent className={styles.cardContent}>
                    {rules.map((rule) => (
                      <Chip
                        key={rule.uuid}
                        label={
                          <span
                            style={{
                              textDecoration: rule.disabled
                                ? "line-through"
                                : undefined,
                            }}
                          >
                            {rule.name}
                          </span>
                        }
                        className={styles.chip}
                        icon={
                          rule.disabled ? (
                            <CheckBoxOutlineBlankIcon
                              onClick={() => onRuleToggle(rule)}
                            />
                          ) : (
                            <CheckBoxIcon onClick={() => onRuleToggle(rule)} />
                          )
                        }
                        deleteIcon={<EditIcon />}
                        onDelete={() =>
                          setLocation(
                            `/building/${building.uuid}/rule/${rule.uuid}/`,
                          )
                        }
                      />
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            )}
            {electricityContracts.length > 0 && (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Card>
                  <CardHeader title={t("Electricity contracts")} />
                  <CardContent className={styles.cardContent}>
                    {electricityContracts.map((contract) => (
                      <Chip
                        key={contract.uuid}
                        label={
                          contract.contract_end === null
                            ? `${contract.contract_start} -`
                            : `${contract.contract_start} - ${contract.contract_end}`
                        }
                        className={styles.chip}
                        deleteIcon={<EditIcon />}
                        onDelete={() =>
                          setLocation(
                            `/building/${building.uuid}/electricityContract/${contract.uuid}/`,
                          )
                        }
                      />
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            )}
            {groups.length > 0 && (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Card>
                  <CardHeader title={t("Groups")} />
                  <CardContent className={styles.cardContent}>
                    <DragDropContext onDragEnd={onGroupDragEnd}>
                      <Droppable droppableId={`groups-${building.uuid}`}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.droppableProps}
                          >
                            {groups.map((group, index) => (
                              <Draggable
                                key={group.uuid}
                                draggableId={group.uuid}
                                index={index}
                              >
                                {(providedDraggable) => (
                                  <div
                                    ref={providedDraggable.innerRef}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...providedDraggable.draggableProps}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...providedDraggable.dragHandleProps}
                                    style={{
                                      ...providedDraggable.draggableProps.style,
                                    }}
                                  >
                                    <Chip
                                      label={group.name}
                                      className={styles.chip}
                                      deleteIcon={<EditIcon />}
                                      onDelete={() =>
                                        setLocation(
                                          `/building/${building.uuid}/group/${group.uuid}/`,
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {rooms.length > 0 && (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Card>
                  <CardHeader title={t("Rooms")} />
                  <CardContent className={styles.cardContent}>
                    <DragDropContext onDragEnd={onRoomDragEnd}>
                      <Droppable droppableId={`rooms-${building.uuid}`}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.droppableProps}
                          >
                            {rooms.map((room, index) => (
                              <Draggable
                                key={room.uuid}
                                draggableId={room.uuid}
                                index={index}
                              >
                                {(providedDraggable) => (
                                  <div
                                    ref={providedDraggable.innerRef}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...providedDraggable.draggableProps}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...providedDraggable.dragHandleProps}
                                    style={{
                                      ...providedDraggable.draggableProps.style,
                                    }}
                                  >
                                    <Chip
                                      label={room.name}
                                      className={styles.chip}
                                      deleteIcon={<EditIcon />}
                                      onDelete={() =>
                                        setLocation(
                                          `/building/${building.uuid}/room/${room.uuid}/`,
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {devices.length > 0 && (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Card>
                  <CardHeader title={t("Devices")} />
                  <CardContent className={styles.cardContent}>
                    <DragDropContext onDragEnd={onDeviceDragEnd}>
                      <Droppable droppableId={`devices-${building.uuid}`}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.droppableProps}
                          >
                            {devices.map((device, index) => (
                              <Draggable
                                key={device.uuid}
                                draggableId={device.uuid}
                                index={index}
                              >
                                {(providedDraggable) => (
                                  <div
                                    ref={providedDraggable.innerRef}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...providedDraggable.draggableProps}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...providedDraggable.dragHandleProps}
                                    style={{
                                      ...providedDraggable.draggableProps.style,
                                    }}
                                  >
                                    <Chip
                                      label={getDeviceText(device.uuid)}
                                      className={styles.chip}
                                      icon={
                                        device.update_status !== undefined &&
                                        [1, 2].includes(
                                          device.update_status,
                                        ) ? (
                                          <PriorityHighIcon
                                            onClick={() =>
                                              setLocation(
                                                `/building/${building.uuid}/device/${device.uuid}/`,
                                              )
                                            }
                                          />
                                        ) : undefined
                                      }
                                      deleteIcon={<EditIcon />}
                                      onDelete={() =>
                                        setLocation(
                                          `/building/${building.uuid}/device/${device.uuid}/`,
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {gateways.length > 0 && (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Card>
                  <CardHeader title={t("Gateways")} />
                  <CardContent className={styles.cardContent}>
                    {gateways.map((gateway) => (
                      <Chip
                        key={gateway.uuid}
                        label={gateway.name}
                        className={styles.chip}
                        deleteIcon={<EditIcon />}
                        onDelete={() =>
                          setLocation(
                            `/building/${building.uuid}/gateway/${gateway.uuid}/`,
                          )
                        }
                      />
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Container>
      </ErrorScreen>
      <ErrorScreen>
        <SpeedDial
          ariaLabel={t("Add")}
          icon={<SpeedDialIcon />}
          FabProps={{ color: "secondary" }}
          sx={{
            zIndex: 100000,
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
        >
          <SpeedDialAction
            icon={<HomeWorkIcon />}
            FabProps={{
              sx: { backgroundColor: (theme) => theme.palette.secondary.main },
            }}
            tooltipTitle={t("State")}
            tooltipOpen
            onClick={() => setLocation(`/building/${building.uuid}/state/`)}
          />
          <SpeedDialAction
            icon={<ShowChartIcon />}
            FabProps={{
              sx: { backgroundColor: (theme) => theme.palette.secondary.main },
            }}
            tooltipTitle={t("Chart")}
            tooltipOpen
            onClick={() => setLocation(`/building/${building.uuid}/chart/`)}
          />
          <SpeedDialAction
            icon={<GavelIcon />}
            FabProps={{
              sx: { backgroundColor: (theme) => theme.palette.secondary.main },
            }}
            tooltipTitle={t("Rule")}
            tooltipOpen
            onClick={() => setLocation(`/building/${building.uuid}/rule/`)}
          />
          <SpeedDialAction
            icon={<GroupWorkIcon />}
            FabProps={{
              sx: { backgroundColor: (theme) => theme.palette.secondary.main },
            }}
            tooltipTitle={t("Group")}
            tooltipOpen
            onClick={() => setLocation(`/building/${building.uuid}/group/`)}
          />
          <SpeedDialAction
            icon={<MeetingRoomIcon />}
            FabProps={{
              sx: { backgroundColor: (theme) => theme.palette.secondary.main },
            }}
            tooltipTitle={t("Room")}
            tooltipOpen
            onClick={() => setLocation(`/building/${building.uuid}/room/`)}
          />
        </SpeedDial>
      </ErrorScreen>
    </Box>
  );
});

export default BuildingSettingsScreen;
